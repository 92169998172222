<template>
  <div class="home animation" style="animation-delay: 1s" v-if="render">
    <!-- intro -->
    <v-container fluid>
      <v-row>
        <v-col style="background: #ece7e1">
          <v-row justify="center" no-gutters>
            <v-col
              class="pa-0 intro position-relative no-height-fixed"
              cols="12"
              sm="10"
            >
              <v-row no-gutters justify="center">
                <v-col
                  cols="auto"
                  class="animation"
                  style="animation-delay: 2s"
                  v-if="$vuetify.breakpoint.xs"
                >
                  <v-img
                    alt="Marisol Logo"
                    class="shrink logo-header mx-auto"
                    contain
                    src="@/assets/icons/MariSolMalibu_logo-NEGRO.svg"
                    transition="scale-transition"
                  />
                  <v-img
                    alt="Marisol Malibu Logo"
                    contain
                    src="@/assets/icons/MALIBU_logo-NEGRO.svg"
                    class="intro__logo-2 mt-5 mx-auto"
                  ></v-img>
                </v-col>
                <v-col cols="12">
                  <!-- btn para el menú mobile -->
                  <div class="text-center mt-6 d-sm-none">
                    <v-btn
                      @click="$emit('open')"
                      :ripple="false"
                      text
                      class="letter-spacing-0 not-text-transform"
                    >
                      <span class="continuos-texts black--text">Menu</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>

              <v-row no-gutters class="mt-12">
                <v-col cols="12" :class="{'margin-top-asset-intro': $vuetify.breakpoint.smAndUp}">
                  <template v-if="homeSingleton.intro_video.path != ''">
                    <video
                      style="object-fit: contain"
                      class="intro__asset no-absolute"
                      loop
                      controls
                      playsinline
                      width="100%"
                      height="100%"
                      autoplay
                      muted
                    >
                      <source
                        :src="
                          'https://content.marisolmalibu.com/storage/uploads' +
                          homeSingleton.intro_video.path
                        "
                      />
                    </video>
                  </template>
                </v-col>

                <v-col cols="12" class="mt-12">
                  <div class="text-center text-sm-left">
                    <span
                      class="black--text subtitles-big d-block sabon-regular animation line-height-title-intro"
                      style="animation-delay: 3s"
                      v-html="homeSingleton.text_intro"
                      >
                    </span>

                    <v-img
                      contain
                      src="@/assets/icons/MALIBU_logo-NEGRO.svg"
                      class="intro__logo-2 mt-5"
                      style="opacity: 0;"
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- /intro -->

    <!-- segundo bloque -->
    <v-container id="home__secondBlock" fluid class="block-second pb-0">
      <v-row no-gutters class="block__second animation-each">
        <v-col>
          <v-row no-gutters class="fill-height" justify="center" align="center">
            <v-col cols="auto" class="text-center">
              <div class="sabon-regular block__second__title-second-paragraph" v-html="homeSingleton.title_zero_series"
                ></div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- /segundo bloque -->

    <v-container fluid class="block__third padding-custom-general">
      <!-- tercer bloque -->
      <v-row justify="center">
        <v-col cols="12" class="px-0 pt-0">
          <v-row no-gutters align="center" justify="center">
            <v-col cols="12" sm="6" order="1" order-sm="0" class="pt-3">
              <v-row no-gutters justify="center">
                <v-col
                  cols="auto"
                  class="block__third__ml-custom text-center text-sm-left animation-each"
                >
                  <div>
                    <h2
                      class="subtitles-big line-height-title founders-grotesk-light pre-line"
                      >
                      {{homeSingleton.texts_sustainably.title}}
                    </h2>
                  </div>

                  <div>
                    <p
                      class="continuos-texts block__third__margin-p line-height-description pre-line"
                    >
                      {{homeSingleton.texts_sustainably.description}}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="block__third__second-column">
              <v-img
                id="firstImage"
                style="transition: opacity 1s ease"
                :src="'https://content.marisolmalibu.com'+homeSingleton.img_sustainably.path"
                class="block__third__img"
                eager
                :alt="getImgTitle(homeSingleton.img_sustainably)"
                v-if="homeSingleton.img_sustainably.path != ''"
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- /tercer bloque -->

      <!-- cuarto bloque -->
      <v-row align="center" class="block mt-custom animation-each">
        <v-col
          cols="12"
          sm="6"
          md="6"
          class="px-0 pt-0 block__sixth__second-column"
        >
          <v-img
            :src="'https://content.marisolmalibu.com'+homeSingleton.img_enduring.path"
            eager
            class="block__sixth__img ml-auto"
            contain
            max-height="631"
            :alt="getImgTitle(homeSingleton.img_enduring)"
            v-if="homeSingleton.img_enduring.path != ''"
          ></v-img>
        </v-col>
        <v-col cols="12" sm="5" md="4" class="resolution-custom">
          <v-row no-gutters justify="center" justify-sm="start">
            <v-col
              cols="auto"
              class="block__sixth__ml-left-custom text-center text-sm-left animation-each"
            >
              <div>
                <h2
                  class="subtitles-big line-height-title founders-grotesk-light pre-line"
                  > 
                    {{homeSingleton.texts_enduring.title}}
                </h2>
              </div>

              <div>
                <p
                  class="continuos-texts block__fourth__margin-p line-height-description pre-line"
                >
                 {{homeSingleton.texts_enduring.description}}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- /cuarto bloque -->

      <!-- quinto bloque -->
      <v-row
        align="center"
        class="block mt-custom animation-each"
        style="flex-direction: row-reverse"
      >
        <v-col
          cols="12"
          sm="6"
          md="6"
          class="px-0 pt-0 block__third__second-column"
        >
          <v-img
            :src="'https://content.marisolmalibu.com'+homeSingleton.img_living.path"
            eager
            contain
            max-height="631"
            class="block__third__img"
            :alt="getImgTitle(homeSingleton.img_living)"
            v-if="homeSingleton.img_living.path != ''"
          ></v-img>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-row
            no-gutters
            justify="center"
          >
            <v-col
              cols="auto"
              class="block__fifth__mr-right-custom text-center text-sm-left animation-each"
            >
              <div>
                <h2
                  class="subtitles-big line-height-title founders-grotesk-light pre-line"
                  >
                  {{homeSingleton.texts_living.title}}
                </h2>
              </div>

              <div>
                <p
                  class="continuos-texts block__fifth__margin-p line-height-description pre-line"
                >
                  {{homeSingleton.texts_living.description}}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- /quinto bloque -->

      <!-- sexto bloque -->
      <v-row justify="center" class="mt-custom animation-each">
        <v-col cols="12" class="px-0 pb-0 pt-0">
          <v-row
            no-gutters
            align="center"
            justify="center"
            style="flex-direction: row-reverse"
          >
            <v-col cols="12" sm="6" order="1" order-sm="0" class="pt-3">
              <v-row no-gutters>
                <v-col
                  cols="auto"
                  class="block__sixth__ml-left-custom text-center text-sm-left animation-each"
                >
                  <div>
                    <h2
                      class="subtitles-big line-height-title founders-grotesk-light pre-line"
                      >
                      {{homeSingleton.texts_peak.title}}
                    </h2>
                  </div>

                  <div>
                    <p
                      class="continuos-texts block__sixth__margin-p line-height-description pre-line"
                      style="max-width: 205px;"
                      v-if="this.$vuetify.breakpoint.xs"
                    >
                      {{homeSingleton.texts_peak.description}}
                    </p>
                    <p
                      class="continuos-texts block__sixth__margin-p line-height-description pre-line"
                      style="max-width: 240px;"
                      v-else
                    >
                      {{homeSingleton.texts_peak.description}}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="block__sixth__second-column">
              <v-img
                :src="'https://content.marisolmalibu.com'+homeSingleton.img_peak.path"
                class="block__sixth__img ml-auto"
                eager
                :alt="getImgTitle(homeSingleton.img_peak)"
                v-if="homeSingleton.img_living.path != ''"
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- /sexto bloque -->
    </v-container>

    <v-container fluid class="py-lg-0 mt-custom">

      <v-row class="block__responsibly-built animation-each" justify="center">
        <v-col cols="12" class="px-0">
          <v-row no-gutters class="fill-height" align="center">
            <v-col cols="auto">
              <div class="text-center animation-each">
                <span
                  class="titles-medium rewrite-in-hd sabon-regular line-height-title-2"
                  v-html="homeSingleton.title_responsibly"
                  >
                </span>
              </div>
              <div class="position-relative">
                <span
                  class="title-house-drawing continuos-texts founders-grotesk-bold uppercase"
                  >Zero 1</span>

                <span
                  class="title-house-drawing continuos-texts founders-grotesk" style="transform: translateY(100%) translateX(-50%);"
                  >11865 Ellice</span>

                <!-- SVG desk -->
                <template v-if="$vuetify.breakpoint.smAndUp">
                  <svgMapZero1></svgMapZero1>
                </template>
                <!-- /SVG desk -->

                <!-- SVG mobile -->
                <template v-if="$vuetify.breakpoint.xs">
                  <svgMapBuiltMobileZero1></svgMapBuiltMobileZero1>
                </template>
                <!-- /SVG mobile -->
              </div>
              <div class="text-center">
                <router-link to="/zero1" class="not-text-decoration btn-black">
                  <span class="white--text continuos-texts"
                    >Discover Paradise</span
                  >
                </router-link>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>


      <!-- bloque responsibly built -->
      <v-row class="block__responsibly-built animation-each" justify="center">
        <v-col cols="12" class="px-0">
          <v-row no-gutters class="fill-height" align="center">
            <v-col cols="auto">
              <div class="text-center animation-each">
                <!-- <span
                  class="titles-medium rewrite-in-hd sabon-regular line-height-title-2"
                  v-html="homeSingleton.title_responsibly"
                  >
                </span> -->
              </div>
              <div class="position-relative">
                <span
                  class="title-house-drawing continuos-texts founders-grotesk-bold uppercase"
                  >Zero 2</span>

                <span
                  class="title-house-drawing continuos-texts founders-grotesk" style="transform: translateY(100%) translateX(-50%);"
                  >11809 Ellice St.</span>

                <!-- SVG desk -->
                <template v-if="$vuetify.breakpoint.smAndUp">
                  <svgMap></svgMap>
                </template>
                <!-- /SVG desk -->

                <!-- SVG mobile -->
                <template v-if="$vuetify.breakpoint.xs">
                  <svgMapBuiltMobile></svgMapBuiltMobile>
                </template>
                <!-- /SVG mobile -->
              </div>
              <div class="text-center">
                <router-link to="/zero2" class="not-text-decoration btn-black">
                  <span class="white--text continuos-texts"
                    >Discover Paradise</span
                  >
                </router-link>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- /bloque responsibly built -->

      <!-- bloque always building... -->
      <v-row id="alwaysBuilding" class="block__always-building">
        <v-col class="px-0 py-0 position-relative">
          <div class="block__always-building--div-center text-center">
            <div class="max-width-always-title">
              <h2
                class="titles-medium rewrite-in-hd black--text line-height-title-2 sabon-regular"
                v-html="homeSingleton.title_always"
              >
              </h2>
            </div>
            <p
              class="continuos-texts block__always-building--div-center__div-max-width line-height-description"
            >
              {{homeSingleton.description_always}}
            </p>
          </div>
          <template v-if="$vuetify.breakpoint.smAndUp">
            <div class="capa-1 rewrite-width-1" :class="'scene-' + scene">
              <div class="capa-2">
                <div class="capa-3">
                  <div class="capa-4">
                    <div class="capa-5">
                      <div class="capa-6">
                        <div class="capa-7"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- slider mobile -->
          <template v-if="$vuetify.breakpoint.xs">
            <carousel
              :loop="true"
              :items="1.5"
              :center="true"
              :margin="1"
              :nav="false"
            >
              <img src="@/assets/img/home/alwaysBuilding/marisol-malibu-zer-2-home.jpg" />
              <img src="@/assets/img/House1-2.jpg" />
              <img src="@/assets/img/House2-mobile.jpg" />
              <img src="@/assets/img/3.jpg" />
              <img src="@/assets/img/House4_Desktop-mobile.jpg" />
              <img src="@/assets/img/House5_Desktop-mobile.jpg" />
              <img src="@/assets/img/House6-mobile.jpg" />
              
            </carousel>
          </template>
          <!-- /slider mobile -->
        </v-col>
      </v-row>
      <!-- /bloque always building... -->
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue'
import carousel from "vue-owl-carousel";
import svgMapZero1 from "../components/MapBuilt";
import svgMapBuiltMobileZero1 from "../components/MapBuiltMobileWithScrollOverflow";
import svgMap from "../components/MapBuiltZero2";
import svgMapBuiltMobile from "../components/MapBuiltMobileWithScrollOverflowZero2";
import VueMeta from 'vue-meta'


Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
  metaInfo: {
    title: 'Zero Carbon Home | Marisol Malibu',
    // titleTemplate: 'Marisol Malibu',
    htmlAttrs: {
      lang: 'en',
      amp: true
    },
  }
})

export default {
  metaInfo() {
    return {
      title: 'Marisol Malibu | Zero Carbon Home',
      // titleTemplate: 'Marisol Malibu',
      htmlAttrs: {
        lang: 'en'
      },
      meta: [
        {name:'keywords', content: 'zero carbon home, sustainably, zero emissions, sustainable living, malibu'}
      ]
    }
  },
  name: "Home",
  components: {
    carousel,
    svgMap,
    svgMapBuiltMobile,
    svgMapZero1,
    svgMapBuiltMobileZero1
  },
  data() {
    return {
      homeSingleton: {
        intro_video: {
          path: ''
        },
        texts_sustainably:{
          title: ''
        },
        img_sustainably:{
          path: ''
        },
        img_enduring:{
          path: ''
        },
        texts_enduring: {
          path: ''
        },
        img_living: {
          path: ''
        },
        texts_living: {
          title: ''
        },
        texts_peak: {
          title: ''
        },
        img_peak: {
          path: ''
        }

      },
      render: false,
      muted: true,
      autoplay: true,
      open_menu: false,
      cover: false,
      scene: 0,
      dialog: false,
    };
  },
  watch: {
    $route() {
      this.open_menu = false;
    },
  },
  methods: {
    getImgTitle: function (imgObject){
      if(Object.prototype.hasOwnProperty.call(imgObject,'meta')){
        return imgObject.meta.title;
      }else{
        return '';
      }
    }
  },
  created() {
    fetch("https://content.marisolmalibu.com/api/singletons/get/home")
      .then((data) => data.json())
      .then((data) => (this.homeSingleton = data))
      .then((this.render = true))
      .catch((error) => console.log(error.message));
  },
  mounted() {

    // this.$gtag.pageview({ page_path: '/' ,page_name: 'Home'})
    var aux = this;
    setInterval(() => {
      aux.cover = !aux.cover;
      if (this.scene < 6) {
        this.scene++;
      } else {
        this.scene = 0;
      }
    }, 3000);

    this.$emit("update", 0);
  },
};
</script>
